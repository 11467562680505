<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="cc-content-richtext">
            <p style="text-align: center">
              <strong>INFORMATIVA PRIVACY</strong><br /><strong
                >ai sensi dell’art. 13 del Regolamento (UE) 2016/679</strong
              ><br /><strong
                >del Parlamento Europeo e del Consiglio (“Regolamento”)</strong
              >
            </p>
            <p style="text-align: justify">
              La Federazione Italiana Tennis e Padel (la
              “<strong>FITP</strong>”) desidera informarLa che ha necessità di
              procedere al trattamento dei Suoi dati personali ai fini
              dell’invio degli inviti in Tribuna d’Onore.
            </p>
            <p style="text-align: justify">
              <strong
                >1. TITOLARE DEL TRATTAMENTO e DATA PROTECTION OFFICER</strong
              ><br />Il Titolare del trattamento è la FITP, con sede legale in
              (00135) Roma, Stadio Olimpico – Curva Nord, Ingresso 44, Scala G
              (il “<strong>Titolare del trattamento</strong>”).<br />
              Il <em>Data Protection Officer</em> è raggiungibile al seguente
              indirizzo: Federazione Italiana Tennis e Padel – Data Protection
              Officer, (00135) Roma, Stadio Olimpico – Curva Nord, Ingresso 44,
              Scala G o tramite p.e.c. a
              <a href="mailto:dpo@pec.federtennis.it">dpo@pec.federtennis.it</a
              >.
            </p>
            <p style="text-align: justify">
              <strong>2. FINALITÀ DEL TRATTAMENTO E BASE GIURIDICA</strong
              ><br />I dati personali trattati sono il nome, il cognome e
              l’indirizzo e-mail Suo e di eventuali accompagnatori. La base
              giuridica del trattamento dei Suoi dati personali è il legittimo
              interesse del Titolare. Inoltre, il Titolare potrebbe trattare i
              Suoi dati per far valere e difendere un proprio diritto in sede
              giudiziaria.
            </p>
            <p style="text-align: justify">
              <strong>3. PERIODO DI CONSERVAZIONE DEI DATI PERSONALI</strong
              ><br />
              Il Titolare del trattamento intende conservare i Suoi dati
              personali per un arco di tempo non superiore rispetto a quello
              necessario per il conseguimento delle finalità per i quali sono
              stati raccolti. I dati saranno conservati per 10 anni dall’invio
              dell’invito.
            </p>
            <p style="text-align: justify">
              <strong>4. CATEGORIE DI SOGGETTI DESTINATARI DEI DATI</strong
              ><br />I dati trattati non saranno oggetto di diffusione a terzi.
              Possono comunque venire a conoscenza dei Suoi dati personali, in
              relazione alle finalità di trattamento precedentemente esposte:<br />•
              &nbsp; &nbsp;i soggetti che possono accedere ai dati in forza di
              disposizione di legge previste dal diritto dell’Unione Europea o
              da quello dello Stato membro cui è soggetto il Titolare del
              trattamento;<br />• &nbsp; &nbsp;i soggetti che svolgono in totale
              autonomia, come distinti Titolari del trattamento, ovvero in
              qualità di Responsabili del trattamento all’uopo nominati dalla
              FITP, finalità ausiliarie alle attività e ai Servizi, le società
              che offrono infrastrutture informatiche e servizi di assistenza
              informatica nonché progettazione e realizzazione di software e
              siti Internet, le società che offrono servizi utili a
              personalizzare e ottimizzare i nostri servizi, società che offrono
              servizi di analisi e sviluppo di dati, centri di servizio, società
              o consulenti incaricati di fornire ulteriori servizi al Titolare
              del trattamento, nei limiti delle finalità per le quali sono stati
              raccolti. <br />Inoltre, potrà venire a conoscenza dei Suoi dati
              personali anche il nostro personale dipendente, purché sia
              precedentemente designato come soggetto autorizzato o come
              Amministratore di Sistema. Per un elenco completo dei destinatari
              può rivolgere una richiesta al Titolare mediante i dati di
              contatto forniti.
            </p>
            <p style="text-align: justify">
              <strong
                >5. TRASFERIMENTO DI DATI PERSONALI VERSO PAESI TERZI</strong
              ><br />Il Titolare del trattamento non trasferirà i Suoi dati
              personali verso paesi terzi al di fuori dell’Unione Europea.
            </p>
            <p style="text-align: justify">
              <strong>6. EVENTUALI PROCESSI DECISIONALI AUTOMATIZZATI</strong
              ><br />Il Titolare del trattamento non intende utilizzare processi
              decisionali automatizzati, ivi compresa la profilazione.
            </p>
            <p style="text-align: justify">
              <strong>7. NATURA DEL CONFERIMENTO</strong><br />Il conferimento
              dei Suoi dati personali ha natura obbligatoria, in quanto un Suo
              eventuale rifiuto a fornire i dati personali richiesti
              comporterebbe l’impossibilità di partecipare all’evento.
            </p>
            <p style="text-align: justify">
              <strong>8. DIRITTI DELL’INTERESSATO</strong><br />
              In relazione al trattamento dei dati personali, ai sensi del
              Regolamento, Lei in qualità di interessato ha il diritto di: i)
              chiedere al Titolare del trattamento l’accesso ai Suoi dati
              personali; ii) ottenere dal Titolare del trattamento la rettifica
              e l’integrazione dei Suoi dati personali ritenuti inesatti, anche
              fornendo una semplice dichiarazione integrativa; iii) ottenere dal
              Titolare del trattamento la cancellazione dei Suoi dati personali;
              iv) ottenere dal Titolare del trattamento la limitazione del
              trattamento dei Suoi dati personali; v) ricevere dal Titolare del
              trattamento i dati personali che La riguardano in un formato
              strutturato, di uso comune e leggibile da dispositivo automatico,
              nonché trasmettere tali dati a un altro titolare del trattamento
              senza impedimenti; vi) opporsi in qualsiasi momento, per motivi
              connessi alla Sua situazione particolare, al trattamento dei Suoi
              dati personali svolto ai sensi dell’art. 6, paragrafo 1, lettera
              e) del Regolamento; vii) proporre reclamo ad un’autorità di
              controllo oppure adire le opportune sedi giudiziarie, qualora
              ritenga che il trattamento che La riguarda violi il Regolamento.

              <br />
              Per esercitare ciascuno dei Suoi diritti, può contattare il
              <em>Data Protection Officer</em>, indirizzando una comunicazione
              presso la sede legale della FITP in (00135) Roma, Stadio Olimpico
              – Curva Nord, Ingresso 44, Scala G, oppure inviando una email
              all’indirizzo
              <span><a href="mailto:privacy@fitp.it">privacy@fitp.it</a></span>
              o una PEC a
              <span
                ><a href="mailto:dpo@pec.federtennis.it"
                  >dpo@pec.federtennis.it</a
                ></span
              >, fornendo nome, cognome e indirizzo postale e dettagli della
              richiesta.<br />
            </p>
            <!-- <p style="text-align: justify">
              <strong
                >11. CONSENSO DEI MINORI IN RELAZIONE AI SERVIZI DELLA SOCIETÀ
                DELL’INFORMAZIONE</strong
              ><br />
              È fatto esplicito divieto ai minori di anni quattordici (14) di
              utilizzare i Servizi erogati tramite il Sito Web. Con l’iscrizione
              alla newsletter sul Sito Web confermi di aver raggiunto i
              quattordici (14) anni.
            </p> -->
            <p style="text-align: justify">&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default defineComponent({
  name: "sanzioni",
  setup() {
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Privacy Policy");
    });
  },
});
</script>
